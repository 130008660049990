<template>
  <div>
    <v-dialog
      elevation="0"
      v-model="dialog"
      max-width="590"
      overlay-color="#19283d"
      overlay-opacity="0.282397"
    >
      <v-card
        max-width=""
        flat
        class="m-0"
        style="background: #f8f7f4; border-radius: 8px"
      >
        <v-card-title
          style="
            background: #ffffff;
            border-radius: 8px 8px 0px 0px;
            height: 54px;
          "
        >
          <img
            :src="require('@/assets/icons/task-square.png')"
            width="20"
            height="20"
          />

          <span class="ml-5 dialog__title">Send Invoice</span>
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog" icon color="primary">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <div class="px-16 mt-10 mb-8">
          <span class="py-14 text-h6" style="color: #9e9ebd">
            Are you sure you want to send this invoice to the customer?
          </span>
        </div>

        <template class="mt-6">
          <v-card-actions class="d-flex justify-end mt-2 mr-14">
            <v-btn
              @click="closeDialog"
              dark
              width="121"
              height="45"
              color="#acb2b6"
              elevation="0"
              class="text-capitalize mt-8 mb-16 mr-5"
            >
              <v-icon small color="primary">mdi-close</v-icon>
              <span class="primary--text">Cancel</span>
            </v-btn>
            <v-btn
              link
              @click="sendInvoice"
              :loading="loading"
              dark
              height="45"
              color="primary"
              class="text-capitalize mt-8 mb-16 px-4"
            >
              <v-icon>mdi-chevron-right</v-icon>
              <span class="px-1">Send</span>
            </v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    //
  },
  name: "sendCustomerInvoice",
  data() {
    return {
      dialog: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      singleInvoice: "invoices/getSingleInvoice",
      newInvoiceData: "invoices/getNewInvoiceData",
    }),
  },

  methods: {
    ...mapActions({ showToast: "ui/showToast" }),

    //close dialog
    closeDialog() {
      this.dialog = false;
    },
    //open dialog
    show(val) {
      this.dialog = val;
    },

    //send invoice to customer
    async sendInvoice() {
      this.loading = true;
      const payload = {
        id: this.singleInvoice?.hypn_id || this.newInvoiceData?.hypn_id,
        send: true,
      };
      try {
        await this.$store
          .dispatch("contacts/sendCustomerInvoice", payload)
          .then((res) => {
            if (process.env.NODE_ENV === "development") {
              console.log(res);
            }
            if (res.status === "success") {
              this.showToast({
                sclass: "success",
                show: true,
                message: "New Customer Invoice sent successfully",
                timeout: 3000,
              });
            }
          });
      } catch (error) {
        console.log(error);
        this.showToast({
          sclass: "error",
          show: true,
          message: "Error sending Customer Invoice",
          timeout: 3000,
        });
      } finally {
        this.loading = false;
        this.closeDialog();
      }
    },
  },
};
</script>

<style scoped>
.v-application .elevation-6 {
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 3%), 0px 6px 10px 0px rgb(0 0 0 / 3%),
    0px 1px 18px 0px rgb(0 0 0 / 3%) !important;
}

.v-input .v-label {
  font-size: 12px !important;
}

.field__title {
  margin-bottom: 20px;
  margin-top: 30px;
  margin-left: 67px;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.45px;
  color: #7f919b;
}

.form__field {
  margin-top: 12px;
  margin-left: 67px;
  margin-right: 67px;
  margin-bottom: 60px;
  /* background: #ffffff; */
  /* border: 1px solid rgba(212, 216, 223, 0.377431); */
  border-radius: 3px;
}

.dialog__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #19283d;
}
</style>
